import React from "react"
import { DataTitle } from '../../data'

export default function Table({children}) {
  return (
    <>
      <div className="flex flex-col w-full">
        <div>
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-900">
                <thead className="bg-primary">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      {DataTitle[0].name}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      {DataTitle[1].name}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      {DataTitle[2].name}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      {DataTitle[3].name}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-base-200 divide-y divide-gray-900">
                  {children}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
