import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

import _ from 'lodash'
import ls from "local-storage"
import Card from "../components/card/data"
import numeral from "numeral"
import Badge from '../components/badge'
import useAxios from 'axios-hooks'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Ships, Fleets } from "../data/index"
import Mud from '../images/mudBgBlank.jpeg'
import Oni from '../images/oniBgBlank.jpeg'
import Ustur from '../images/usturBgBlank.jpeg'

export default function Index() {

  // Captures Star Atlas Data
  const [{ data: atlasData, loading: atlasLoading, error: atlasError }] = useAxios(
    'https://galaxy.staratlas.com/nfts/'
  )
  // Fetch Atlas & Polis Price
  const [{ data: atlasPrice, loading: atlasPriceLoading, error: atlasPriceError }] = useAxios(
    `https://api.coingecko.com/api/v3/coins/star-atlas`
  )
  const [{ data: polisPrice, loading: polisPriceLoading, error: polisPriceError }] = useAxios(
    `https://api.coingecko.com/api/v3/coins/star-atlas`
  )
  const [itemDisplay, setItemDisplay] = useState('Ships')
  
  // On render, set default to Deep Profits unless local storage exists.
  let activeFleet;
  let url;
  
  if(!ls.get("activeFleet")) {
    // If no local storage
    activeFleet = "Deep Profits"
    url = `https://deepprofits.org/star_atlas_fleet/fleet_generator.php?f=deep_profits_fleet`
  } else { 
    // If local storage
    activeFleet = ls.get("activeFleet")
    Fleets.forEach(fleet => {
      if(fleet.name == activeFleet) {
        url = `https://deepprofits.org/star_atlas_fleet/fleet_generator.php?f=${fleet.endpoint}`
      }
    })
  }

  // Set Active Theme
  let activeTheme;
  if(!ls.get("theme")) {
    activeTheme =  "ustur"
  } else { // Restore Local Storage
    activeTheme = ls.get("theme") 
  }
 
  // Define active fleet & theme
  const [currentFleet, setCurrentFleet] = useState(activeFleet)
  const [theme, setTheme] = useState(activeTheme)

  // Store clicked fleet in local storage and prepare axios request. 
  // Default is Deep Profits
  let activeNav = ls.get("activeFleet")
  function changeFleet(e) {
    setCurrentFleet(e.target.value);
    ls.set("activeFleet", `${e.target.value}`);
    activeNav = currentFleet
  }

  // Refetch Fleet Data
  const [{ data, loading, error }, refetch] = useAxios(
      url
  )

  // Store theme in local storage
  function changeTheme(e) {
    setTheme(e.target.className);
    ls.set("theme", `${e.target.className}`);
  }

  // Toggle Banners
  let bannerBG = Mud
  if(theme === 'mud') {
    bannerBG = Mud
  } else if(theme === 'oni') {
    bannerBG = Oni
  } else {
    bannerBG = Ustur
  }
  
  // Dynamic Banner BG
  const bgStyles = {
    backgroundImage: `url(${bannerBG})`,
    backgroundPosition: `center`,
    backgroundSize: `cover`
  }

  // Compare Fleet & Atlas Data
  let totalFleetValue = []
  if(atlasData && data && atlasPrice && polisPrice) {
    atlasData.forEach(atlas => {
      Ships.forEach(ship => {
        if(ship.name === atlas.symbol && ship.name) {
          totalFleetValue.push(data[`${ship.name}`]?.qty*atlas?.tradeSettings.msrp.value)
        }
      })
    })
    totalFleetValue.push(atlasPrice?.tickers[0].last*data['ATLAS'].qty)
    totalFleetValue.push(polisPrice?.tickers[0].last*data['POLIS'].qty)
  }
  
  return (
    <>
    <Layout>
      <SEO title="Fleet Pass" />
        <div className="flex flex-wrap" data-theme={theme}>
          {/* Sidebar */}
          <div className="w-full lg:w-1/5">
            <div className="sticky top-4 custom-width mb-3">
              <ul className="menu py-3 shadow-lg bg-base-200 rounded-box">
                <li className="menu-title">
                  <span className="title">
                    Select A Fleet
                    <span className="faction-select">
                      <span className="oni" onClick={changeTheme}>&nbsp;</span>
                      <span className="mud" onClick={changeTheme}>&nbsp;</span>
                      <span className="ustur" onClick={changeTheme}>&nbsp;</span>
                    </span>
                  </span>
                </li>
                <li>
                  <Link to="/get-started">
                    <label className="cursor-pointer label text-primary">Get Your Fleet Pass</label>
                  </Link>
                </li>                
                {Fleets.map((fleet, id) => (
                  <li key={id}>
                    <a
                      className={
                        activeNav == fleet.name ? "active" : "inactive"
                      }
                    >
                      <label className="cursor-pointer label">
                        <span className="label-text">{fleet.name}</span>
                        <input
                          onClick={changeFleet}
                          type="radio"
                          name="opt"
                          data-name={fleet.endpoint}
                          className="radio hidden"
                          value={fleet.name}
                        />
                      </label>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div class="btn-group">
              <button data-button="display-type" onClick={() => setItemDisplay('Ships')} className={`btn btn-xs btn-${itemDisplay === 'Ships' ? 'active' : null}`}>Ships</button> 
              <button data-button="display-type" onClick={() => setItemDisplay('Posters')} className={`btn btn-xs btn-${itemDisplay === 'Posters' ? 'active' : null}`}>Posters</button> 
            </div> */}
          </div>
          {/* End Sidebar */}
          {/* Page Data */}
          <div className="w-full lg:w-4/5">
            <div className="grid md:grid-cols-2 gap-3 md:gap-10">
              <div
                className="fleet-banner w-full shadow-lg bg-base-200 rounded-box"
                style={bgStyles}
              />
              <div className="fleet-details p-10 shadow-lg bg-base-200 rounded-box">
                <div>
                  <div className="fleet-intro flex">
                    <h1 className="mr-5">{activeFleet}</h1>
                    {data ? 
                    <>
                      <div className="coin mr-5 inline-flex">
                        <Badge color="primary" loading={loading} label={`Atlas: **${numeral(data['ATLAS'].qty).format('0,0.00')}**`} />
                      </div>
                      <div className="coin inline-flex">
                        <Badge color="primary" loading={loading} label={`Polis: **${numeral(data['POLIS'].qty).format('0,0.00')}**`} />
                      </div>
                    </>
                    : null }
                  </div>
                  <div className="stat-value text-secondary my-2">
                    {loading ? 
                      <SkeletonTheme baseColor="transparent" highlightColor="#555">
                        <Skeleton />
                      </SkeletonTheme>
                      : 
                      `${numeral(_.sum(totalFleetValue)).format('$0, $0.00')} TFV`
                    }
                  </div>
                  <div className="stat-desc whitespace-normal">
                    {Fleets.map((ship) => (
                      ship.name === activeFleet ? 
                      <>
                        <p>{ship.description}</p>
                        {ship.link ? 
                          <p><a href={ship.link} target="_blank" className="link link-secondary flex mt-2">Read More</a></p>
                        : null }
                      </>
                        : null
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-10">
              <div className="mx-auto">
                <div className="md:mt-10 flex flex-wrap">
                  <Card 
                    itemDisplay={itemDisplay}
                    data={data} 
                    loading={loading}
                    atlas={atlasData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
    </>
  );
}
