import React from 'react'
import { Link } from 'gatsby'
// import Modal from './modal'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import numeral from 'numeral'
import { DataTitle } from '../../data'

export default function Card(props) {

    let msrp;
    if(props.atlas) {
      props.atlas.forEach(atlas => {
        if(atlas.mint == props.mint) {
          msrp = atlas.tradeSettings.msrp.value
        }
      })
    }

    let titleFormat = props.title.toLowerCase()
    let link = titleFormat.replace(/\s/g, '-')
    if(link === 'the-calico-evac') link = 'calico-evac'

    return (
        <>
            <figure>
                <Link to={`/ships/${link}`}>
                    <LazyLoadImage 
                        src={props.image}
                        className="rounded-xl"
                        effect="blur"
                    />
                </Link>
            </figure> 
            <div className="card-body">
            <SkeletonTheme baseColor="transparent" highlightColor="#555">
                {props.loading ? 
                    <Skeleton />
                    :
                    <h2 className="card-title">{props.title} <span className="text-secondary">x {props.qty}</span></h2>
                } 
                {props.loading ? 
                    <Skeleton count={2} />
                :
                <>
                    <p><strong>{DataTitle[1].name}:</strong> {props.dimensions}</p>
                    <p><strong>{DataTitle[4].name}:</strong> {props.rarity ? props.rarity : 'Unknown'}</p>
                    <p><strong>{DataTitle[3].name}:</strong> {numeral(msrp).format('$0, $0.00')}</p>
                </>
                }
                {/* UNCOMMENT FOR MODAL BUTTON *****
                <div className="justify-center card-actions">
                    <Modal data={props.data} />
                </div> */}
            </SkeletonTheme>
            </div>
        </> 
    )
}
