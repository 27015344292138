import React, { useState, useEffect } from 'react'
import useAxios from "axios-hooks"
import Card from './index'
import Modal from './settings'
import Table from '../table/index'
import Rows from '../table/rows'
import { Ships, Posters } from '../../data'
import _ from 'lodash'
import ls from 'local-storage'

import Row from '../../images/row.svg'
import Grid from '../../images/grid.svg'

export default function Data(props) {

    let displayData = props.data
    let allAtlas = props.atlas
    const allPosters = []
    
    const [display, setDisplay] = useState("row")
   
    function toggleDisplay(e) {
        setDisplay(e.target.getAttribute("data-display"))
        ls.set('display', `${display}`)
    }

    // Fetch Posters
    // allAtlas.forEach(atlas => {
    //     Posters.forEach(poster => {
    //         let posterName = displayData[poster.name]
    //         if(atlas._id === posterName._id) {
    //             allPosters.push(atlas)
    //         }
    //     })
    // })
    
  return (
    <>

        {/* TOGGLE DISPLAY MODE */}
        <div className="w-full toggle-display flex ml-3 md:justify-end md:mr-10 md:ml-0">
            <div data-tip="Row" className="tooltip">
                <span className="flex">
                    <img onClick={toggleDisplay} data-display="row" src={Row} className="w-5 h-5 cursor-pointer filter invert" />
                </span>
            </div>
            <div data-tip="Grid" className="tooltip">
                <span className="flex ml-3">
                    <img onClick={toggleDisplay} data-display="grid" src={Grid} className="w-5 h-5 cursor-pointer filter invert" />
                </span>
            </div>
            {/* <div data-tip="Settings" className="tooltip">
                <span className="flex ml-3">                    
                    <label for="my-modal-2" class="modal-button"><img src={Settings} className="w-5 h-5 cursor-pointer filter invert" /></label> 
                    <input type="checkbox" id="my-modal-2" class="modal-toggle" />
                    <Modal />
                </span>
            </div> */}
        </div>

        {/* DATA VIEWS */}
        {displayData ?
        display == 'grid' ?
        <div className="card-wrapper w-full">
            {props.itemDisplay === 'Ships' ?
            Ships.map((ship, id) => {
                        if(typeof displayData[ship.name] !== 'undefined') {
                            return <div key={id} className="card text-center shadow-lg bg-base-200">
                                <Card
                                    loading={props.loading}
                                    atlas={props.atlas}
                                    image={displayData[`${ship.name}`]["image"]}
                                    title={displayData[`${ship.name}`]["ship"]}
                                    rarity={displayData[`${ship.name}`]["ship rarity"]}
                                    mint={displayData[`${ship.name}`]["mint"]}
                                    spec={displayData[`${ship.name}`]["ship spec"]}
                                    dimensions={displayData[`${ship.name}`]["ship spec"]}
                                    qty={displayData[`${ship.name}`]["qty"]}
                                />
                            </div>
                        }
                })
                :
                allPosters.map((poster, id) => {
                    return <div key={id} className="card text-center shadow-lg bg-base-200">
                        <Card
                            image={poster.image}
                            title={poster.name}
                            rarity={poster.attributes.rarity}
                            mint={poster.mint}
                            spec={poster.network}
                            qty={poster.totalSupply}
                        />
                    </div>
            })
            }
            </div>
            :
            <Table>
                {props.itemDisplay === 'Ships' ?
                    Ships.map((ship) => {
                        if(typeof displayData[ship.name] !== 'undefined') {
                        return <Rows
                            loading={props.loading}
                            atlas={props.atlas}
                            image={displayData[`${ship.name}`]["image"]}
                            title={displayData[`${ship.name}`]["ship"]}
                            rarity={displayData[`${ship.name}`]["ship rarity"]}
                            mint={displayData[`${ship.name}`]["mint"]}
                            spec={displayData[`${ship.name}`]["ship spec"]}
                            qty={displayData[`${ship.name}`]["qty"]}
                        />
                        }
                    })
                    :
                    allPosters.map((poster, id) => {
                        return <Rows
                            image={poster.image}
                            title={poster.name}
                            rarity={poster.attributes.rarity}
                            mint={poster.mint}
                            spec={poster.network}
                            qty={poster.totalSupply}
                        />
                    })
                }
            </Table>
        : null }
    </>
  )
}