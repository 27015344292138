import React from "react"
import { Link } from 'gatsby'
import numeral from 'numeral'
import _ from 'lodash'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { DataTitle } from '../../data'

export default function Rows(props) {

  let msrp;
  let totalSupply;
  if(props.atlas) {
    props.atlas.forEach(atlas => {
      if(atlas.mint == props.mint) {
        msrp = atlas.tradeSettings.msrp.value
        if(atlas.totalSupply) {
          totalSupply = atlas.totalSupply
        } else {
          totalSupply = '?'
        }
      }
    })
  }

  let titleFormat = props.title.toLowerCase()
  let link = titleFormat.replace(/\s/g, '-')
  if(link === 'the-calico-evac') link = 'calico-evac'

  return (
    <>    
      <tr className="row-border">
        {props.loading ?
        <>
          <SkeletonTheme baseColor="transparent" highlightColor="#555">
            <td colSpan="5" className="row-placeholder">
              <Skeleton />
            </td>
          </SkeletonTheme>
        </>
        :
        <>
        <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`/ships/${link}`}>
          <div className="flex items-center image-name-wrap">
            <div className="mobile-only text-secondary">
              {DataTitle[0].mobileName}              
            </div>
            <div className="flex-shrink-0 h-10 w-10 desktop-only mr-4">
              <LazyLoadImage 
                className="h-10 w-10 rounded-full"
                src={props.image}
                alt={props.title}
              />
            </div>
            <div>
              <div className="flex-shrink-0 h-10 w-10 mobile-only">
                <LazyLoadImage 
                  className="h-10 w-10 rounded-full"
                  src={props.image}
                  alt={props.title}
                />
              </div>
              <div className="text-sm font-medium mt-2">{props.title}</div>
              {/* <div className="text-sm text-gray-500">{`${ship.shipLength} x ${ship.shipWidth} x ${ship.shipHeight}`}</div> */}
            </div>
          </div>
          </Link>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="mobile-only text-secondary">
            {DataTitle[1].mobileName}
          </div>
          <div className="inline-flex flex-col">
            <div className="text-sm">{props.spec}</div>
            <div className="text-sm text-gray-500">{props.shipRarity}</div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="mobile-only text-secondary">
            {DataTitle[2].mobileName}
          </div>
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-secondary num-count">
            {props.qty}
          </span>
        </td>
        {/* <td className="px-6 py-4 whitespace-nowrap">
          <div className="mobile-only text-secondary">Supply</div>
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-secondary num-count">
            {totalSupply}
          </span>
        </td> */}
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
          <div className="mobile-only text-secondary">
           {DataTitle[3].mobileName}
          </div>
          {numeral(msrp).format("$0, $0.00")}
        </td>
        </>
        }
      </tr>
    </>
  )
}
