import React from 'react'
import ReactMarkdown from 'react-markdown'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

{/* 
    Available Colors:
    - Primary
    - Secondary
    - Accent
    - Ghost
*/}

export default function Badge(props) {
    return (
        <>
        {!props.loading ? 
            <div
                className={`badge ${props.color ? `badge-${props.color}` : ''}`}
            >
                <ReactMarkdown children={props.label} />
            </div> 
            : null
        }
        </>
    )
}